var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-right","bg-variant":"white","visible":_vm.isSidebarActive,"right":"","backdrop":"","shadow":"","title":"Sidebar","sidebar-class":"sidebar-lg","no-header":""},on:{"hidden":function($event){_vm.resetForm();
    _vm.$refs.addreviewForm.reset();},"change":function (val) { return _vm.$emit('update:is-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between p-2"},[_c('h4',{},[_vm._v("Add review")]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":hide}})],1),_c('div',{staticClass:"px-2 mt-2"},[_c('validation-observer',{ref:"addreviewForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Review","label-for":"review"}},[_c('validation-provider',{attrs:{"name":"Review","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"comment","placeholder":"Review"},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Rating","label-for":"rating"}},[_c('validation-provider',{attrs:{"name":"Rating","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating","placeholder":"Rating","type":"number"},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Reviewer","label-for":"reviewername"}},[_c('validation-provider',{attrs:{"name":"Reviewer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reviewername","placeholder":"Reviewer"},model:{value:(_vm.reviewername),callback:function ($$v) {_vm.reviewername=$$v},expression:"reviewername"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Photo","label-for":"photo"}},[_c('validation-provider',{attrs:{"name":"Photo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"photo","placeholder":"Photo"},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Order","label-for":"orderby"}},[_c('validation-provider',{attrs:{"name":"Order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderby","placeholder":"Order","type":"number"},model:{value:(_vm.orderby),callback:function ($$v) {_vm.orderby=$$v},expression:"orderby"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 mt-1",attrs:{"type":"submit","requi":"","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }