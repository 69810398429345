import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usereviewList() {

    const toast = useToast();


    const pageLength = ref(10);

    const columns = [
        {
            label: "Review",
            field: "comment",
        },
        {
            label: "Rating",
            field: "rating",
        },
        {
            label: "Reviewer",
            field: "reviewername",
        },
        {
            label: "Image",
            field: "photo",
        },
        {
            label: "Order",
            field: "orderby",
        },
        {
            label: "Status",
            field: "status",
        },
        {
            label: "Action",
            field: "action",
        },
    ];

    var searchTerm = ref('');


    const review = ref([]);

    const statusVariant = computed(() => {
        const statusColor = {
            1: "light-success",
            0: "light-danger",
        };
        return (status) => statusColor[status];
    })

    const fetchreview = async () => {
        store
            .dispatch('master-review/fetchreview', {})
            .then(response => {

                if (response.data.result == 1) {

                    review.value = response.data.data;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }

            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Fetching review',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }


  
    const refetchreview = () => {
        fetchreview();
    }


    const addreview = async (formData) => {
        return store
            .dispatch('master-review/addreview', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding review',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }


    const updatereview = async (formData) => {
        return store
            .dispatch('master-review/updatereview', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding review',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const deletereview = async (formData) => {
        return store
            .dispatch('master-review/deletereview', formData)
            .then(response => {

                if (response.data.result == 1) {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                    return true;

                } else {

                    toast({
                        component: ToastificationContent,
                        props: {
                            title: `${response.data.msg}`,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })


                }



            })
            .catch((error) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error: Adding review',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
                return false;
            })
    }

    const addUser = async (formData) => {
        return store
            .dispatch('app-user/addUser', formData)
            .then(response => {
                console.log(response);
                if (response.data.success && response.data.success == true) {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'User added successfuly',
                            icon: 'CheckIcon',
                            variant: 'Success',
                        },
                    })
                    return true;
                } else {
                    return { error: response.data.status };
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error while adding user',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                });
                return false;
            })
    }



    return {
        fetchreview,
        addreview,
        refetchreview,
        updatereview,
        deletereview,
        columns,
        pageLength,
        searchTerm,
        review,
        statusVariant,


    }
}